import React from 'react'
import SVG from 'react-inlinesvg'
import SvgSprite from '@sm360/summary-360/dist/assets/svg-sprite.svg'

import { runtimeConfig } from '../config/runtimeConfig'

import SummaryView from '../../views/summary/Summary.view'

const SummarySSR = () => {
    React.useEffect(() => {
        if (typeof window === 'undefined') {
            global.window = {}
        }
    }, [])
    const svgBaseUrl = runtimeConfig.REACT_APP_ASSETS_PATH ? `${runtimeConfig.REACT_APP_ASSETS_PATH}/summary-360` : ''
    return (
        <>
            <SVG
                src={`${svgBaseUrl}${SvgSprite}`}
                style={{ display: 'none' }}
                onError={(error) => console.log('svg error', error)}
            />

            <SummaryView />
        </>
    )
}

export default SummarySSR
