import PropTypes from 'prop-types'
import React from 'react'
import clsx from 'clsx'

import {
    EquipmentsAndAccessories,
    Heading,
    PackageOptionsAndAccessories,
    VehicleDescription,
    VehicleHighlightCards,
    VehicleSpecs,
} from '@sm360/components'

import { useTranslation } from '@sm360/hooks'
import { SummaryContext } from '../../configs/summary-view/state.manager'

import { quoteDeskingTransform } from '../../transforms/quoteDesking.transform'

const PageSections = ({ extraClasses }) => {
    const {
        state: {
            showroomQuote,
            showroom360ConfigVdp: { summaryHighlightCardConfigurations, summaryVDPSectionsConfig },
            vehicleTrim: { vehicleTrim },
            dealerInfos: { lang },
        },
    } = React.useContext(SummaryContext)

    const { t } = useTranslation()

    const baseClass = 's360-o-pageSections'
    const classNames = clsx(baseClass, extraClasses)

    const { vehicle, quoteInfos } = quoteDeskingTransform(showroomQuote, t) || {}

    const {
        model,
        exteriorColourPhotos,
        interiorTrimPhotos,
        consumption,
        bodyStyle,
        bodyClassification,
        seating,
        doors,
        engine,
        transmission,
        driveTrain,
        description,
    } = vehicle || {}

    const { accessoriesAndOptions, fuel, specifications } = vehicleTrim || {}

    const highlights = summaryHighlightCardConfigurations

    const { id: quoteId } = quoteInfos || {}

    const renderVehicleComponent = ({ slug, title, index }) => {
        const commonProps = {
            headingTitle: title,
            key: index,
        }

        switch (slug) {
            case 'specifications':
                return (
                    <VehicleSpecs
                        {...commonProps}
                        specs={{
                            consumption,
                            frameCategory: bodyClassification?.frameCategory?.frameCategory,
                            bodyStyle: bodyStyle?.name,
                            doors,
                            passengers: seating,
                            engineType: engine?.name,
                            engineDescription: engine?.power,
                            fuel: fuel?.name,
                            transmission: transmission?.name,
                            gears: transmission?.speeds,
                            driveTrain: driveTrain?.name,
                            exteriorColor: exteriorColourPhotos?.description,
                            interiorColor: interiorTrimPhotos?.colourDescription,
                        }}
                        showOdometer={false}
                        extraClasses={`${baseClass}__vehicleSpecs`}
                    />
                )

            case 'description':
                return <VehicleDescription {...commonProps} description={description} model={model?.name} />

            case 'options-accessories':
                return (
                    <PackageOptionsAndAccessories
                        {...commonProps}
                        optionsAndAccessories={accessoriesAndOptions}
                        displayLimit={false}
                        showEmptyElem={false}
                        headingClass='il-heading3'
                        extraClasses={`${baseClass}__vehicleOptions`}
                    >
                        <Heading extraClasses={`${baseClass}__header il-heading3`} Tag={'h2'} variant='heading3'>
                            {t('vehicle.accessoriesAndOptions')}
                        </Heading>
                    </PackageOptionsAndAccessories>
                )

            case 'standard-equipments':
                return (
                    <EquipmentsAndAccessories
                        {...commonProps}
                        specifications={specifications}
                        lang={lang}
                        extraClasses={`${baseClass}__equipmentsAndAccessories`}
                    >
                        <Heading extraClasses={`${baseClass}__header il-heading3`} Tag={'h2'} variant='heading3'>
                            {t('vehicle.equipmentsAndAccessories')}
                        </Heading>
                    </EquipmentsAndAccessories>
                )

            case 'highlights':
                return highlights?.length > 0 ? (
                    <VehicleHighlightCards
                        {...commonProps}
                        vehicleInventory={vehicle}
                        highlights={highlights}
                        quoteId={quoteId}
                    />
                ) : null

            default:
                return null
        }
    }

    return (
        <div className={classNames}>
            {summaryVDPSectionsConfig.map(
                ({ active, title, slug }, index) => active && renderVehicleComponent({ slug, title, index })
            )}
        </div>
    )
}

PageSections.propTypes = {
    /** Extra classes */
    extraClasses: PropTypes.string,
}

PageSections.defaultProps = {
    extraClasses: '',
}

export default PageSections
