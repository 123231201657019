import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import ChainedBackend from 'i18next-chained-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import { runtimeConfig } from '../../widget-ssr/config/runtimeConfig'

import { getTranslationsAPIUrl } from '../../api/showroom360/showroom360.service'

const backendOptions = {
    loadPath: '{{lng}}|{{ns}}',
    request: (options, url, payload, callback) => {
        try {
            const [lng] = url.split('|')
            const dummyData = {}
            if (runtimeConfig.REACT_APP_DOMAIN) {
                callback(null, {
                    data: dummyData,
                    status: 200,
                })
            } else {
                getTranslationsAPIUrl(lng).then((response) => {
                    callback(null, {
                        data: response,
                        status: 200,
                    })
                })
            }
        } catch (e) {
            callback(null, {
                status: 500,
            })
        }
    },
}

i18n.use(ChainedBackend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        detection: {
            order: ['path'],
        },
        // preload: ['en', 'fr'],
        nonExplicitSupportedLngs: true,
        load: 'languageOnly',
        fallbackLng: 'en',
        backend: {
            backends: [Backend],
            backendOptions: [backendOptions],
        },
        ns: ['translations', 'defaultTranslations'],
        defaultNS: 'translations',
        fallbackNS: 'defaultTranslations',
        // debug: process.env.NODE_ENV === 'development',
        react: {
            useSuspense: true,
        },
    })

export default i18n
